.q-carousel__navigation-icon--inactive{
    background-color:$secondary;
    &:hover{
        border:none;
        background-color:$secondary;
    }
}

.q-carousel__navigation-icon--active{
    background-color:$primary;
    &:hover{
        border:none;
        background-color:$primary;
    }
}