//////////////////////////////////////////////////// FORM

.input-style{
    text-align: center;
    color: $secondary;
}

.q-input {
    width: 60vw;
    margin: auto;
}

.form {
    max-width: 60vw;
    margin: auto;
}

///////////////////////////////////////////////////// CHECKBOX
.q-checkbox__inner {
    color: unset;
}
.q-checkbox__label {
    color: $primary;
}
.q-checkbox__inner .q-checkbox__bg {
    background: $secondary;
    border: 2px solid $primary;
}
.q-checkbox__svg{
    color: $primary;
}

//////////////////////////////////////////////////// Q-FIELD

// ERROR COLOR
.q-field--error .q-field__bottom{
    color: $primary;
}

// FOCUS COLOR
.q-field__control {
    color: $primary;
    height: 41px;
    &:focus-within{
      .q-field__marginal{
        color: $primary;
      }
    }
}

// WIDGET COLOR
.q-field__marginal {
    color:$secondary;
    height: 41px;
    & ~ div:focus-within{
      color: $primary;
    }
}

//LABEL COLOR
.q-field__label {
    color: $secondary;
}

// BORDER RADIUS
.q-field--outlined.q-field--rounded .q-field__control {
    border-radius: $border-radius;
    border-color:$primary;
    background-color: $primary;
    color: $secondary;
    &:focus-within{
      color: $primary;
      background-color: $secondary;

      .q-field__native, .q-field__input {
        color: $primary;
      }
    }
}

.q-field__control:before {
    border-color:$primary;
}

.q-field__native, .q-field__input {
    color: $secondary;
    text-align: center;
    @extend .default-font-size;
}
#formValidChallenge .q-field__native, .q-field__input {
    color: $primary;
    text-align: center;
    @extend .default-font-size;
}
.q-field__native textarea{
  color:$secondary!important;
  text-align: left!important;
  &:focus{
      color: $secondary!important;
    }
}
.details-bubble .q-field__native{
  color:$secondary!important;
  &:focus{
      color: $secondary!important;
    }
}


//////////////////////////////////////////////////// Q-RADIO
.q-radio{
  background-color: $primary;
  color: $secondary;
  width: 350px!important;
  padding: 10px;
  margin: 10px 0;
  border-radius: $border-radius;
  .q-radio__inner{
    opacity: 0;
    position: fixed;
    width: 0;
  }
  .q-radio__label{
    margin: 0 auto;
  }
}
.q-radio[aria-checked="true"]{
  background-color: $secondary;
  color: $primary;
  border: $primary solid 2px;
}




