@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
@font-face{
  font-family: "FatFace";
  src: url('../assets/fonts/AbrilFatface-Regular.ttf') format('truetype');
}
body{
  font-family: Helvetica, Roboto;
  font-weight:400;
  background-color: $background;
  max-width: 100%;
}
a{
  text-decoration: none;
}

h1 {
  font-size: xx-large;
  color: $primary;
}
h2 {
  font-size: x-large;
}
h3 {
  font-size: large;
}

h1, h2, h3{
  font-family: "FatFace";
}

p{
  color: $primary;
  font-size: 1.25rem;
}

.default-font-size {
  font-size: 1rem;
}

.property-icon {
  font-size:$property-list-height;
}

.check-icon {
  font-size:5rem;
}

@import 'form.scss';
@import 'btn.scss';
@import 'carousel.scss';

