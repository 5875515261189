.border-btn{
    border: 2px solid $primary;
  }

  .text-btn{
    text-transform: capitalize;
    color: $primary;
    background-color: $secondary;
  }

  .def-btn{
    @extend .border-btn;
    @extend .text-btn;
  }

  .top-btn-spacing {
    margin-block:$property-list-height/2;
  }
  .btn-spacing {
    margin-bottom:$property-list-height/2;
  }

  .property-list{
    display:flex;
    flex-direction:column;
    justify-content:center;
    gap:3rem;

    div {
      font-size:$property-list-height/2.5;
      line-height:$property-list-height;
    }
  }

.q-btn {
    background-color:$primary;
    color:$secondary;
    border-radius: 25px;
    text-transform: none;
    box-sizing: none;
    box-shadow: none;
    @extend .default-font-size;
      &:hover{
        background-color: $secondary;
        color: $primary;
        transition: 0.4s ease-in-out;
        border: 2px solid $primary;
      }
      &:before{
        border: none;
      }
}

.q-btn--actionable.q-btn--standard:active:before, .q-btn--actionable.q-btn--standard.q-btn--active:before {
  box-shadow: none;
}

q-focusable:focus, .q-manual-focusable--focused, .q-hoverable:hover
    > .q-focus-helper{
      background: transparent;
    }
